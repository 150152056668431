import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import CallAction from "../elements/callaction/CallAction";
import FooterTwo from "../component/footer/FooterTwo";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='PHOENICS Schulung' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">PHOENICS Schulung</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Es gibt zwei Möglichkeiten um PHOENICS zu lernen.</p>
                                                    <p>Für Einsteiger empfehlen wir die Vor-Ort Schulung. In einem Tag sind Sie unterwegs mit PHOENICS.</p>
                                                    <p>Die Vor-Ort-Schulung ist eine maßgeschneiderte individuelle Schulung (max. 5 Personen), die sehr praxisorientiert ist: wenig Theorie und viele Tipps und Tricks und Beispiele aus Ihrem Unternehmen bzw. Geschäftsbereich.</p>
                                                    <p>Wenn Sie tiefer ins Programm gehen möchte, können Sie zu London abreisen und ein 3-Tagskurs machen in CHAM HQ.</p>
{/*                                                     <h4 className="title">Eigenschaften</h4>
                                                    <ul className="liststyle">
                                                        <li></li>
                                                    </ul>
 */}                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
{/*                                         <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p><a href="http://www.cham.co.uk/Flair.php" target="_blank">FLAIR</a> ist ein PHOENICS Zusatzmodul für die Technische Gebäudeausrüstung (TGA). FLAIR ist sehr geeignet für Komfortberechnugen, Windsimulationen und Brandsimulationen.</p>
                                                    <p>Sie bekommen FLAIR kostenfrei, wenn Sie eine Jahres- oder Dauerlizenz kaufen.</p>
                                                    <h4 className="title">Lizenzen</h4>
                                                    <ul className="liststyle">
                                                        <li>Jahreslizenz: € 7.200,=</li>
                                                        <li>Dauerlizenz: € 18.000,=</li>
                                                        <li>Preise für Multi-User und Multi-Prozessoren auf <a href="/contact">Anfrage</a>.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='8FyWPy_PsGE' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div>
 */}                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/* Start call To Action  */}
                 <CallAction />
                {/* End call To Action  */}
              
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;