import React, { Component } from "react";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Gebäudetechnik',
        title: 'Komfort in einem Besprechungsraum'
    },
    {
        image: 'image-21',
        category: 'Gebäudetechnik',
        title: 'Hochleistungs-Rechenzentrum'
    },
    {
        image: 'image-31',
        category: 'Windsimulation',
        title: 'Eine alte Gleishalle'
    },
    {
        image: 'image-41',
        category: 'Gebäudetechnik',
        title: 'CO-Konzentrationen in einer Tiefgarage'
    },
    {
        image: 'image-51',
        category: 'Gebäudetechnik',
        title: 'Eine Skihalle'
    },
    {
        image: 'image-61',
        category: 'Gebäudetechnik',
        title: 'Übertragungsrisiko von Corona durch Hallen- Großveranstaltungen'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    {/* <h4><a href="/portfolio-details">{value.title}</a></h4> */}
                                    <h4><a href={`/portfolio-details_${index}`}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`/portfolio-details_${index}`}>Details anzeigen</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;