import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
// import Footer from "../component/footer/Footer";
import FooterTwo from "../component/footer/FooterTwo";

/* const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/CHAM-153288288825455'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/cham_uk/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/cham_uk/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/CHAM_UK'},
]
 */
class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
 
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Übertragungsrisiko von Corona durch Hallen- Großveranstaltungen' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Übertragungsrisiko von Corona durch Hallen- Großveranstaltungen</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h2>Trydo</h2> */}
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        <p>Coolplug hat <a href="https://www.zbpleipzig.de/arena-leipzig-stroemungssimulation/" target="_blank" rel="noopener noreferrer">Planungsbüro Zimmermann und Becker</a> unterstützt mit den PHOENICS CFD Simulationen für die Leizig Arena.</p>
                                        <p>Die Martin Luther Universität Halle Wittenberg beauftragt seit Juli 2020 Planungsbüro Zimmermann und Becker mit der Untersuchung der Raumluftströmung in der Arena Leipzig, um Aussagen zum Infektionsrisiko treffen zu können.</p>
                                        <p>Nach aktuellem Kenntnisstand bilden Aerosole einen der Hauptübertragungswege von SARS-CoV-2. Im Detail und in der konkreten Situation muss daher die Lüftungswirksamkeit mit betrachtet werden, die abhängig ist von der Luftwechselrate und der Art der Luftverteilung. Eine hohe Luftwechselrate (Zu-und Abluftluftmenge pro Raumvolumen) erhöht dabei vor allem bei geringen Emissionsraten die Aufenthaltszeit im Raum beträchtlich. Die Lüftungseffektivität beschreibt dabei die Verteilung der ausgeatmeten Luft einer infektiösen Person zu anderen Personen im Raum. Folglich sind eine gute Verdünnung der Atemluft und eine gute Führung der Abluft entscheidend, um das Infektionsrisiko in Innenräumen zu senken.</p>
                                        <p>Die Untersuchungen basieren auf aktuellen wissenschaftlichen Veröffentlichungen zum Infektionsrisiko und deren komplexen Umsetzung in Strömungssimulationen.</p>
 {/*                                         <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Branch</span>
                                                <h4>Ability</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Website</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Program</span>
                                                <h4>View Project</h4>
                                            </div>
                                        </div>
 */}

{/*                                         <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
 */}
                                     </div>
                                    <div className="portfolio-thumb-inner">
                                         {/* <div className="thumb position-relative mb--30"> */}
                                         <div className="thumb position-relative mb--30">
                                             <p>Aufnahme des Livestreams der RESTART-19 Pressekonferenz vom 29.10.2020.</p>
                                            <img src="/assets/images/portfolio/Arena_pressekonferenz.jpg" alt="Arena Pressekonferenz"/>
                                            <ModalVideo channel='youtube'  isOpen={this.state.isOpen} videoId='uqZFgEQiHps' youtube={{start:1524,showinfo:1,controls:1,autoplay:1}} onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <p>3D-Modell Arena Leipzig</p>
                                            <img src="/assets/images/portfolio/3D-Modell-Arena-Leipzig.png" alt="Arena Leipzig"/>
                                       </div>
{/*                                        <div className="thumb mb--30">
                                           <p>3D-Modell Arena Leipzig – Ansicht auf die Tribünen</p>
                                            <img src="/assets/images/portfolio/3D-Modell-Arena-Leipzig_Ansicht-der-Tribünen.jpg" alt="Arena Leipzig"/>
                                       </div>
 */}
{/*                                         <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
 */}
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Verwandte Arbeit</span>
                                    <h2>Weitere Beispiele</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details_1">
                                            <img src="/assets/images/portfolio/related-image-21.jpg" alt="Rechenzentrum"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details_1">Rechenzentrum</a></h4>
                                        <span className="category">Gebäudetechnik</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details_2">
                                            <img src="/assets/images/portfolio/related-image-31.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details_2">Eine alte Gleishalle</a></h4>
                                        <span className="category">Windsimulation</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
