import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
// import Footer from "../component/footer/Footer";
import FooterTwo from "../component/footer/FooterTwo";

/* const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/CHAM-153288288825455'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/cham_uk/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/cham_uk/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/CHAM_UK'},
]
 */
class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='CO-Konzentrationen in einer Tiefgarage' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--41"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">CO-Konzentrationen in einer Tiefgarage</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h2>Trydo</h2> */}
                                        {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
                                        <p>Eine kleine private Tiefgarage möchte die Installation eines teuren Lüftungssystems vermeiden.</p>
                                        <p>Die Behörden würden dies nur genehmigen, wenn der Eigentümer nachweisen könnte, dass die natürliche Belüftung der Tiefgarage einen 0,5-fachen Luftwechsel gewährleistet und dass die halbstündige durchschnittliche CO-Konzentration von 50 ppm für ein Szenario mit drei Autofahrten innerhalb von 30 Minuten nicht überschritten wird.</p>
                                        <p>Es wurde vereinbart, transiente CFD Simulationen mit PHOENICS für zwei atmosphärische Bedingungen durchzuführen. Eine mit einer Windgeschwindigkeit von 3,6 m / s aus West-Nordwest und eine ohne Wind. Die Außentemperatur wurde auf 20 °C eingestellt.</p>
                                        <p>Glücklicherweise konnte nachgewiesen werden, dass in beiden Fällen die Forderungen der Behörden erfüllt werden konnten.</p>

{/*                                         <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Branch</span>
                                                <h4>Ability</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Website</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Program</span>
                                                <h4>View Project</h4>
                                            </div>
                                        </div>
 */}

{/*                                         <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
 */}
                                     </div>
                                    <div className="portfolio-thumb-inner">
{/*                                         <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
 */}                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-41.jpg" alt="Tiefgarage"/>
                                        </div>

{/*                                         <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div>
 */}
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Verwandte Arbeit</span>
                                    <h2>Weitere Beispiele</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details_2">
                                            <img src="/assets/images/portfolio/related-image-31.jpg" alt="Gleishalle"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details_2">Gleishalle</a></h4>
                                        <span className="category">Windsimulation</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details_4">
                                            <img src="/assets/images/portfolio/related-image-51.jpg" alt="Skihalle"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details_4">Skihalle</a></h4>
                                        <span className="category">Gebäudetechnik</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />  


            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
