import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiPhone , FiMail , FiMapPin,FiMessageSquare } from "react-icons/fi";
// import GoogleMapReact from 'google-map-react';
// import ContactTwo from "../elements/contact/ContactTwo";
// import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Obfuscate from "react-obfuscate";

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Kontakt' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Kontakt mit uns</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhone />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Telefonnummer</h4>
                                        {/* https://www.evernote.com/shard/s311/nl/54685241/aa1fa716-f649-4033-a528-4061570f6cce/ */}
                                        <p><Obfuscate tel="+31457850262"/></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMessageSquare />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">WhatsApp</h4>
                                        <p><Obfuscate href="https://wa.me/31622448246">Chatten Sie auf WhatsApp</Obfuscate></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">E-Mail Addresse</h4>
                                        {/* <p><a href="mailto:info@qqq.qqq">myEmail</a></p> */}
                                        <p><Obfuscate 
                                            email="info@coolplug.com"
                                            headers={{subject: 'PHOENICS Anfrage'}}/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Standort</h4>
                                        <p>Noorderkroon 10 </p>
                                        <p>6814 KL  Heerlen</p>
                                        <p> Niederlande</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
{/*                 <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
 */}
                {/* End Contact Page Area  */}

                 {/* Start Contact Map  */}
{/*                 <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                        />
                        </GoogleMapReact>
                    </div>
                </div>
 */}
                 {/* End Contact Map  */}
 
                {/* Start Brand Area */}
{/*                 <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
 */}
                 {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />
                
            </React.Fragment>
        )
    }
}
export default Contact