// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import Demo from './home/Business';
// import MainDemo from './home/MainDemo';
// import Startup from './home/Startup';
// import Paralax from './home/Paralax';
// import HomePortfolio from './home/HomePortfolio';
// import DigitalAgency from './home/DigitalAgency';
// import CreativeAgency from './home/CreativeAgency';
// import PersonalPortfolio from './home/PersonalPortfolio';
// import Business from './home/Business';
// import StudioAgency from './home/StudioAgency';
// import PortfolioLanding from './home/PortfolioLanding';
// import CreativeLanding from './home/CreativeLanding';
// import HomeParticles from './home/HomeParticles';
// import CreativePortfolio from './home/CreativePortfolio';
// import DesignerPortfolio from './home/DesignerPortfolio';

// Dark Home Layout 
// import DarkMainDemo from './dark/MainDemo';
// import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
// import Service from "./elements/Service";
// import ServiceDetails from "./elements/ServiceDetails";
import ServiceDetails_0 from "./elements/ServiceDetails_0";
import ServiceDetails_1 from "./elements/ServiceDetails_1";
import ServiceDetails_2 from "./elements/ServiceDetails_2";
import ServiceDetails_3 from "./elements/ServiceDetails_3";
import About from "./elements/About";
import Contact from "./elements/Contact";

// import PortfolioDetails from "./elements/PortfolioDetails";
import PortfolioDetails_0 from "./elements/PortfolioDetails_0";
import PortfolioDetails_1 from "./elements/PortfolioDetails_1";
import PortfolioDetails_2 from "./elements/PortfolioDetails_2";
import PortfolioDetails_3 from "./elements/PortfolioDetails_3";
import PortfolioDetails_4 from "./elements/PortfolioDetails_4";
import PortfolioDetails_5 from "./elements/PortfolioDetails_5";
// import Blog from "./elements/Blog";
// import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";


// Blocks Layout

// import Team from "./blocks/Team";
// import Counters from "./blocks/Counters";
// import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
// import VideoPopup from "./blocks/VideoPopup";
// import Gallery from "./blocks/Gallery";
// import Brand from "./blocks/Brand";
// import ProgressBar from "./blocks/ProgressBar";
// import ContactForm from "./blocks/ContactForm";
// import GoogleMap from "./blocks/GoogleMap";
// import Columns from "./blocks/Columns";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/> */}

{/*                     <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio}/>
 */}

                    {/* Element Layot */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details_0`} component={ServiceDetails_0}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details_1`} component={ServiceDetails_1}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details_2`} component={ServiceDetails_2}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details_3`} component={ServiceDetails_3}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details_0`} component={PortfolioDetails_0}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details_1`} component={PortfolioDetails_1}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details_2`} component={PortfolioDetails_2}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details_3`} component={PortfolioDetails_3}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details_4`} component={PortfolioDetails_4}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details_5`} component={PortfolioDetails_5}/>
{/*                     <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>
 */}

                    {/* Blocks Elements  */}
{/*                     <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
 */}                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
{/*                     <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
 */}

                    
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();