import React ,{ Component }from "react";
import { FiUsers , FiMonitor } from "react-icons/fi";
import { FiShoppingBag, FiBookOpen } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiShoppingBag />,
        title: 'PHOENICS Lizenzen',
        description: 'Wenn Sie selber CFD Simulationen durchführen möchten.'
    },
    { 
        icon: <FiMonitor />,
        title: 'CFD Dienstleistungen',
        description: 'Wir führen gern die CFD Simulationen für Ihre Projekte durch.'
    },
    {
        icon: <FiUsers />,
        title: 'PHOENICS Support',
        description: 'Wenn Sie Hilfe mit Ihrer PHOENICS Simulation brauchen, sind wir für Sie da.'
    },
    {
        icon: <FiBookOpen />,
        title: 'PHOENICS Schulung',
        description: 'Für einen schnellen Einstieg in der PHOENICS Welt bieten wir mehrere Schulungen an.'
/*     },
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
 */
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                            {/* veranderd zodat gelinkt wordt naar /service-details_0 enz. */}
                            <a href={`/service-details_${i}`}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
